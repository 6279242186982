import { Property } from "./interfaces";

export const tipoMedicamento: Property = {
    title: "Tipo de Medicamento",
    type: ["string", "null"],
    oneOf: [
        { const: "01", title: "01: Medicamento con uso con registro sanitario" },
        { const: "02", title: "02: Medicamento con uso como vital no disponible definido por INVIMA" },
        { const: "03", title: "03: Preparacion magistral" },
        { const: "04", title: "04: Medicamento con uso no incluido en el registro sanitario (Listado UNIRS)" },
        { const: "05", title: "05: Medicamento con autorizacion sanitaria de uso emergencia ASUE" },
    ]
}