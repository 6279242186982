import { Property } from "./interfaces";

export const tipoDocumentoIdentificacion: Property = {
  title: "Tipo de Documento de Identificación",
  type: "string",
  oneOf: [
    { const: "AS", title: "AS: Adulto sin identificar" },
    { const: "CC", title: "CC: Cédula de ciudadanía" },
    { const: "CD", title: "CD: Carné diplomático" },
    { const: "CE", title: "CE: Cédula de extranjería" },
    { const: "CN", title: "CN: Certificado de nacido vivo" },
    { const: "DE", title: "DE: Documento Extranjero" },
    { const: "MS", title: "MS: Menor sin identificar" },
    { const: "PA", title: "PA: Pasaporte" },
    { const: "PE", title: "PE: Permiso especial de permanencia" },
    { const: "RC", title: "RC: Registro civil" },
    { const: "SC", title: "SC: Salvoconducto" },
    { const: "TI", title: "TI: Tarjeta de identidad" },
    { const: "DE", title: "DE: Documento extranjero" },
    { const: "PT", title: "PT: Permiso temporal de permanencia" },
  ],
};
