import { Property } from "./interfaces";

export const formaFarmaceutica: Property = {
    title: "Forma Farmacéutica",
    type: ["string","null"],
    oneOf: [
        { const: "C28944", title: "C28944: CREMA" },
        { const: "C29167", title: "C29167: LOCION" },
        { const: "C29269", title: "C29269: ENJUAGUE" },
        { const: "C42887", title: "C42887: AEROSOL" },
        { const: "C42902", title: "C42902: CAPSULA DE LIBERACION RETARDADA" },
        { const: "C42905", title: "C42905: TABLETAS DE LIBERACION RETARDADA" },
        { const: "C42909", title: "C42909: GRANULOS EFERVESCENTES" },
        { const: "C42912", title: "C42912: ELIXIR" },
        { const: "C42914", title: "C42914: EMULSION INYECTABLE" },
        { const: "C42916", title: "C42916: CAPSULA DE LIBERACION PROLONGADA" },
        { const: "C42927", title: "C42927: TABLETAS DE LIBERACION PROLONGADA" },
        { const: "C42932", title: "C42932: CINTA ADHESIVA / PELICULA" },
        { const: "C42933", title: "C42933: GAS" },
        { const: "C42938", title: "C42938: GRANULOS CONVENCIONALES" },
        { const: "C42941", title: "C42941: GOMA" },
        { const: "C42942", title: "C42942: IMPLANTE" },
        { const: "C42948", title: "C42948: GELES y JALEAS" },
        { const: "C42953", title: "C42953: LIQUIDO (Diferentes a soluciones)" },
        { const: "C42966", title: "C42966: UNGÜENTO" },
        { const: "C42967", title: "C42967: PASTA" },
        { const: "C42968", title: "C42968: SISTEMAS TRANSDERMICOS" },
        { const: "C42969", title: "C42969: PELLETS" },
        { const: "C42983", title: "C42983: JABONES Y CHAMPU" },
        { const: "C42989", title: "C42989: ESPRAY" },
        { const: "C42993", title: "C42993: SUPOSITORIO / OVULO" },
        { const: "C42994", title: "C42994: SUSPENSION" },
        { const: "C42996", title: "C42996: JARABE" },
        { const: "C43000", title: "C43000: TINTURA" },
        { const: "C47913", title: "C47913: EMPLASTO" }
    ]
    
}