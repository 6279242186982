import { Property } from "./interfaces";

export const codSexoBiologico: Property = {
    title: "Código de Sexo Biológico",
    type: "string",
    oneOf: [
        { const: "H", title: "H: Hombre" },
        { const: "I", title: "I: Indeterminado o Inersexual" },
        { const: "M", title: "M: Mujer" },
    ]
}